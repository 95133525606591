// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-container {
  width: 100%;
  height: 100%;
}


.profile-image {
  width: 175px;
  height: 175px;
  object-fit: cover;
  border-radius: 50%;
}

.profile-image-div {
  position: relative;
}

.profile_image_label {
  position: absolute;
  bottom: 0;
}

.edit-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: white;
  color: #012970;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  border: 1px solid #01297033;
}

.edit-icon:hover {
  background-color: #e0e0e0;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  min-height: 15px;
}

.passport-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  min-height: 200px;
}`, "",{"version":3,"sources":["webpack://./src/template/page/personal_details/css/personalDetails.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;;AAGA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,uBAAuB;EACvB,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sCAAsC;EACtC,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,eAAe;EACf,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".profile-container {\n  width: 100%;\n  height: 100%;\n}\n\n\n.profile-image {\n  width: 175px;\n  height: 175px;\n  object-fit: cover;\n  border-radius: 50%;\n}\n\n.profile-image-div {\n  position: relative;\n}\n\n.profile_image_label {\n  position: absolute;\n  bottom: 0;\n}\n\n.edit-icon {\n  position: absolute;\n  bottom: 5px;\n  right: 5px;\n  background-color: white;\n  color: #012970;\n  border-radius: 50%;\n  padding: 8px;\n  cursor: pointer;\n  font-size: 16px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transition: background-color 0.3s ease;\n  border: 1px solid #01297033;\n}\n\n.edit-icon:hover {\n  background-color: #e0e0e0;\n}\n\n.error-message {\n  color: red;\n  font-size: 14px;\n  margin-top: 5px;\n  min-height: 15px;\n}\n\n.passport-image {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  border-radius: 5px;\n  min-height: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
