import { useContext, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../../context/Context';
import { FormNav } from '../../components/nav/FormNav';
import ExperianceDetailsForm from './forms/ExperianceDetailsForm';
import PortfolioDetailsFrom from './forms/PortfolioDetailsForm';
import SkillDetailsForm from './forms/SkillDetailsForm';
import FormFooterButtons from '../../components/footer/FormFooterButtons';
import professionalDetailsValidationSchema from './statistics/professionalDetailsValidationSchema';
import showSnackbar from '../../components/message/showSnackbar';
import { Skeleton } from '@mui/material';

const ProfessionalDetailsPage = () => {
  const navigate = useNavigate();
  const {
    professionalDets,
    saveProfessionalDetails,
    getProfessionalDetails,
    setSpineersLoader,
    loading,
    setUnSaveAppData,
    setIsCompleteAppInfoVisible,
  } = useContext(MyContext);
  useEffect(() => {
    getProfessionalDetails();
  }, []);
  return (
    <section>
      <FormNav />
      {loading ? (
        <>
          <Skeleton variant="rectangular" animation="wave" width="100%" height={100} />
          <Skeleton variant="rectangular" animation="wave" width="100%" height={400} />
          <Skeleton variant="rectangular" animation="wave" width="100%" height={400} />
        </>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={professionalDets}
          onSubmit={async (value) => {
            setSpineersLoader(true);
            const responseData = await saveProfessionalDetails(value);
            if (responseData.success) {
              if (responseData.message) {
                showSnackbar(responseData.message, {
                  variant: 'success',
                });
              }
              setIsCompleteAppInfoVisible(true);
              navigate(responseData.location);
            }
            if (!responseData.success) {
              showSnackbar(responseData.message, {
                variant: 'error',
                autoHideDuration: 10000,
              });
            }
            setSpineersLoader(false);
          }}
          validationSchema={professionalDetailsValidationSchema}
        >
          {(props) => {
            setUnSaveAppData(props.values);
            return (
              <Form className="flex">
                <ExperianceDetailsForm formController={props} />
                <SkillDetailsForm formController={props} />
                <PortfolioDetailsFrom formController={props} />
                <FormFooterButtons />
              </Form>
            );
          }}
        </Formik>
      )}
    </section>
  );
};

export default ProfessionalDetailsPage;
