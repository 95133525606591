const PersonalDetailsItialValues = {
  firstname: '',
  lastname: '',
  email: '',
  contactNo: '',
  candidatePhoto: '',
  passPortPhoto: '',
  birthDate: null,
  age: '',
  gender: '',
  permanentStreet: '',
  permanentCity: '',
  permanentPincode: '',
  permanentState: '',
  permanentCountry: '',
  sameAsPermanentAddress: false,
  communicationStreet: '',
  communicationCity: '',
  communicationPincode: '',
  communicationState: '',
  communicationCountry: '',
  nationality: '',
};

export default PersonalDetailsItialValues;
