import { Box, Typography } from '@mui/material';

export const ViewField = (props) => {
  const { name, value, css } = props;
  return (
    <Box className={css}>
      <Typography variant="caption" sx={{ color: 'gray', letterSpacing: 1.2 }}>
        {name}
      </Typography>
      <Typography sx={{ letterSpacing: 1 }}>
        {value}
      </Typography>
    </Box>
  );
};
