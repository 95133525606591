import React from 'react';
import { Typography } from '@mui/material';
import TagIcon from '@mui/icons-material/Tag';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { ViewDividerWithTitle } from '../controls/ViewDividerWithTitle';
import { ViewField } from '../controls/ViewField';
import { ImagePopup } from '../../../components/img/ImagePopup';
import {
  ViewDetailsContentItems,
  ViewDetailsItemTwo,
  ViewDetailsItemWithChips,
  ViewDetailsTitleWithIcon,
  ViewDetailsWrap,
} from '../../../../style/components/ApplicationView.styled';
import { ViewChip } from '../controls/ViewChip';

const capitalizeFirstChar = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const OtherDetailsView = ({ details }) => {
  const {
    educationDetails,
    coursesDetails,
    bankDetails,
    emergencyContactDetails,
    personalDetails,
  } = details;

  const changeDateFormat = (props) => {
    const propDate = props !== undefined && props !== '' ? props : Date();
    return moment(propDate).format('MMMM D, YYYY');
  };
  return (
    <ViewDetailsWrap>
      {/* 1) Education details  */}
      {!isEmpty(educationDetails) ? (
        <ViewDetailsContentItems>
          <ViewDividerWithTitle title={'Education Details'} />

          {educationDetails.map((eduDets, index) => {
            return (
              <React.Fragment key={index}>
                <ViewDetailsTitleWithIcon>
                  <TagIcon color="primary" />
                  <Typography variant="h6">{`Education Details ${index + 1}`}</Typography>
                </ViewDetailsTitleWithIcon>
                <ViewDetailsItemTwo>
                  <ViewField
                    name={'Degree'}
                    value={educationDetails[index].degree?.label}
                  />
                  <ViewField
                    name={'Stream'}
                    value={educationDetails[index].stream?.label}
                  />
                  <ViewField
                    name={'Board or University'}
                    value={capitalizeFirstChar(educationDetails[index].boardOrUniversity)}
                  />
                  <ViewField
                    name={'Institute'}
                    value={capitalizeFirstChar(educationDetails[index].institute)}
                  />
                  <ViewField
                    name={'GPA or Percentage'}
                    value={capitalizeFirstChar(educationDetails[index].gpaOrPercentage)}
                  />
                  <ViewField
                    name={'Passing Year'}
                    value={
                      educationDetails[index].passingYear &&
                      moment(
                        changeDateFormat(educationDetails[index].passingYear),
                      ).format('LL')
                    }
                  />
                </ViewDetailsItemTwo>
              </React.Fragment>
            );
          })}
        </ViewDetailsContentItems>
      ) : null}

      {/* 2) course details  */}
      {!isEmpty(coursesDetails) && !isEmpty(coursesDetails[0].courseName) ? (
        <ViewDetailsContentItems>
          <ViewDividerWithTitle title={'Courses Details'} />

          {coursesDetails.map((course, index) => {
            return (
              <React.Fragment key={index}>
                <ViewDetailsTitleWithIcon>
                  <TagIcon color="primary" />
                  <Typography variant="h6">{`Course ${index + 1}`}</Typography>
                </ViewDetailsTitleWithIcon>
                <ViewDetailsItemTwo>
                  <ViewField
                    name={'Passing Course name'}
                    value={capitalizeFirstChar(coursesDetails[index].courseName)}
                  />
                  <ViewField
                    name={'Institute'}
                    value={capitalizeFirstChar(coursesDetails[index].institute)}
                  />
                  <ViewField
                    name={'date'}
                    value={
                      coursesDetails[index].completionDate &&
                      moment(coursesDetails[index].completionDate).format('LL')
                    }
                  />
                </ViewDetailsItemTwo>
              </React.Fragment>
            );
          })}
        </ViewDetailsContentItems>
      ) : null}

      {/* 3) Bank details */}
      {!isEmpty(bankDetails) ? (
        <ViewDetailsContentItems>
          <ViewDividerWithTitle title={'Bank Details'} />
          <ViewDetailsItemTwo>
            <ViewField name={'Bank name'} value={bankDetails.bankName} />
            <ViewField name={'Bank branch'} value={capitalizeFirstChar(bankDetails.branch)} />
            <ViewField
              name={'Account holder name'}
              value={bankDetails.accountHolder}
            />
            <ViewField name={'Account No.'} value={bankDetails.accountNo} />
            <ViewField name={'IFSC No.'} value={bankDetails.ifscNo} />
            <ImagePopup
              img={bankDetails.document}
              alt={'bank document'}
              skeletonRatio={120}
              skeletonVariant="rectangular"
              css="pos-end-row-span"
            />
          </ViewDetailsItemTwo>
        </ViewDetailsContentItems>
      ) : null}
      {/* 4) Emergency Details */}
      {!isEmpty(emergencyContactDetails) ? (
        <ViewDetailsContentItems>
          <ViewDividerWithTitle title={'Emergency Details'} />
          {emergencyContactDetails.map((emrgCnt, index) => {
            return (
              <React.Fragment key={index}>
                <ViewDetailsTitleWithIcon>
                  <TagIcon color="primary" />
                  <Typography variant="h6">{`Emergency Contact ${index + 1}`}</Typography>
                </ViewDetailsTitleWithIcon>
                <ViewDetailsItemTwo>
                  <ViewField
                    name={'Full name.'}
                    value={capitalizeFirstChar(emergencyContactDetails[index].fullname)}
                  />
                  <ViewField
                    name={'Relation'}
                    value={capitalizeFirstChar(emergencyContactDetails[index].relation)}
                  />
                  <ViewField
                    name={'Contact No.'}
                    value={emergencyContactDetails[index].contactNo}
                  />
                  <ViewField
                    name={'City'}
                    value={capitalizeFirstChar(emergencyContactDetails[index].city)}
                  />
                  <ViewField
                    name={'Note'}
                    value={capitalizeFirstChar(emergencyContactDetails[index].note)}
                    css="col-span-four"
                  />
                </ViewDetailsItemTwo>
              </React.Fragment>
            );
          })}
        </ViewDetailsContentItems>
      ) : null}
      <ViewDetailsContentItems>
        <ViewDividerWithTitle title={'Other Details'} />
        <ViewDetailsItemWithChips>
          <ViewChip name={'Languages'} isFirstCharCapital value={personalDetails.languages} />
          <ViewChip name={'Hobbies'} isFirstCharCapital value={personalDetails.hobbies} />
        </ViewDetailsItemWithChips>
        <ViewDetailsItemTwo>
          <ViewField
            name={'Marital Status'}
            value={capitalizeFirstChar(personalDetails.maritalStatus)}
          />
          {personalDetails.maritalStatus === 'married' && (
            <>
              <ViewField
                name={'Partner Name'}
                value={personalDetails.partnerName}
              />
              <ViewField
                name={'Marriage Anniversary'}
                value={
                  personalDetails.marriageAnniversary &&
                  changeDateFormat(personalDetails.marriageAnniversary)
                }
              />
            </>
          )}
          <ViewField name={'Blood Type'} value={capitalizeFirstChar(personalDetails.bloodType)} />
        </ViewDetailsItemTwo>
      </ViewDetailsContentItems>
    </ViewDetailsWrap>
  );

};


