import { Chip } from '@mui/material';
import { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import {
  TagAdd,
  TagAddBtn,
  TagAddContainer,
  TagError,
  TagInput,
  TagWrap,
  TagWrapperr,
} from '../../../../style/elements/form/FormTag.styled';

export const FormTag = ({
  label,
  push,
  remove,
  taggArr,
  dir,
  wd,
}) => {
  const [addTag, setAddTag] = useState({
    tag: '',
    errorStatus: false,
    errMsg: '',
  });
  const handleAddTag = (e) => {
    setAddTag((prevData) => {
      const { name, value } = e.target;
      return {
        ...prevData,
        [name]: value,
      };
    });
  };
  const handleTag = () => {
    if (
      taggArr.some(
        (item) => item.toLowerCase() === addTag.tag.toLowerCase(),
      ) === true
    ) {
      setAddTag({
        tag: '',
        errorStatus: true,
        errMsg: 'Already exists',
      });
    } else if (addTag.tag === '') {
      setAddTag({
        tag: '',
        errorStatus: true,
        errMsg: 'Please enter a value',
      });
    } else {
      push(addTag.tag);
      setAddTag({ tag: '' });
    }
  };
  const handleAddTagBtn = () => {
    handleTag();
  };
  const handleEnterAdd = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      handleTag();
    }
  };
  return (
    <TagWrapperr dir={dir}>
      <TagAddContainer>
        <TagAdd>
          <TagInput
            placeholder={label}
            name="tag"
            value={addTag.tag}
            wd={wd}
            onChange={handleAddTag}
            onKeyPress={handleEnterAdd}
          />
          <TagAddBtn onClick={handleAddTagBtn}>
            <FaPlus />
          </TagAddBtn>
        </TagAdd>
        {addTag.errorStatus === true ? (
          <TagError wd={wd}>{addTag.errMsg}</TagError>
        ) : null}
      </TagAddContainer>
      <TagWrap>
        {taggArr !== null && taggArr !== undefined && taggArr.length > 0
          ? taggArr.map((item, index) => (
            <span key={index}>
              <Chip label={item} onDelete={() => remove(index)} color="primary" variant="outlined" />
            </span>
          ))
          : null}
      </TagWrap>
    </TagWrapperr>
  );
};
