import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { ButtonWrapper } from '../../../style/components/Button.styled';
import { Flex } from '../../../style/utils/Flex.styled';
import { MyContext } from '../../context/Context';
import { useLocation, useNavigate } from 'react-router-dom';

const FormFooterButtons = () => {
  const {
    cPath,
    spineersLoader,
    unSavedAppData,
    professionalDets,
    otherDets,
  } = useContext(MyContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [nevigatePath, setNavigatePath] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const navigationPaths = {
    "/other-details": "/professional-details",
    "/professional-details": "/personal-details",
  };
  const handleBackRedirect = () => {
    let isUnsaved = false;
    if (pathname === "/professional-details" && JSON.stringify(professionalDets) !== JSON.stringify(unSavedAppData)) {
      isUnsaved = true;
    }

    if (pathname === "/other-details" && JSON.stringify(otherDets) !== JSON.stringify(unSavedAppData)) {
      isUnsaved = true;
    }

    if (isUnsaved) {
      const previousPath = navigationPaths[cPath];
      if (previousPath) {
        setNavigatePath(previousPath)
      }
      setIsDialogOpen(true);
    } else {
      const previousPath = navigationPaths[cPath];
      if (previousPath) {
        navigate(previousPath);
      }
    }

  }
  const handleDiscard = () => {
    navigate(nevigatePath);
    setIsDialogOpen(false);
  }
  const handleCancel = () => {
    setIsDialogOpen(false);
  };
  return (
    <>
      <ButtonWrapper
        disp="flex"
        jc={cPath === '/personal-details' ? 'flex-end' : 'space-between'}
        bg="white"
        pd="1rem"
      >
        {(cPath === "/other-details" || cPath === "/professional-details") && (
          <Button
            disableElevation
            onClick={handleBackRedirect}
          >
            Back
          </Button>
        )}
        <Flex gap="1rem">
          <Button
            type="submit"
            variant="contained"
            disableElevation
            disabled={spineersLoader}
          >
            <Box display="flex" alignItems="center" gap={1}>
              {cPath === '/other-details' ? 'Submit' : 'Save & Next'}
              {spineersLoader && <CircularProgress color="inherit" size={16} />}
            </Box>
          </Button>
        </Flex>
      </ButtonWrapper>
      <Dialog
        open={isDialogOpen}
        // onClose={handleCancel}
        onClose={(event, resone) => {
          if (resone !== "backdropClick") {
            handleCancel();
          }
        }}
        aria-labelledby="unsaved-changes-dialog"
        PaperProps={{
          style: {
            borderRadius: "12px",
            padding: "16px",
            backgroundColor: "#f9f9f9",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <DialogTitle
          id="unsaved-changes-dialog"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            fontWeight: "600",
            fontSize: "1.2rem",
            color: "#333",
          }}
        >
          <Typography component="span" style={{ color: "#f64f39" }}>
            ⚠️
          </Typography>
          Unsaved Changes
        </DialogTitle>
        <DialogContent style={{ marginTop: "8px" }}>
          <Typography
            style={{
              fontSize: "1rem",
              color: "#555",
              marginBottom: "20px",
            }}
          >
            You have unsaved changes on this page. Are you sure you want to leave without saving?
          </Typography>
          <Typography
            style={{
              fontSize: "0.9rem",
              color: "#888",
              marginBottom: "16px",
            }}
          >
            Any changes you made will be lost if you proceed.
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "space-between",
            padding: "16px",
            gap: "8px",
          }}
        >
          <Button
            onClick={handleDiscard}
            variant="contained"
            color="error"
            style={{
              backgroundColor: "#f64f39",
              color: "#fff",
              textTransform: "capitalize",
              fontWeight: "500",
            }}
          >
            Discard Changes
          </Button>
          <Button
            onClick={handleCancel}
            variant="outlined"
            style={{
              textTransform: "capitalize",
              fontWeight: "500",
              borderColor: "#59B2f4",
              color: "#59B2f4",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormFooterButtons;
