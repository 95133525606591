import { useContext, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { MyContext } from '../../context/Context';
import { FormNav } from '../../components/nav/FormNav';
import BankDetailsForm from './forms/BankDetailsForm';
import EducationDetailsForm from './forms/EducationDetailsForm';
import CoursesDetailsForm from './forms/CoursesDetailsForm';
import EmergencyContactDetailsForm from './forms/EmergencyContactDetailsForm';
import FormFooterButtons from '../../components/footer/FormFooterButtons';
import AdditionalDetailsForm from './forms/AdditionalDetailsForm';
import otherDetailsValidationSchema from './statistics/otherDetailsValidationSchema';
import showSnackbar from '../../components/message/showSnackbar';
import { Skeleton } from '@mui/material';

const OtherDetailsPage = () => {
  const {
    appIds,
    getOnBoardAppDets,
    otherDets,
    saveOtherDetails,
    getCandidateOtherDetails,
    setSpineersLoader,
    loading,
    setUnSaveAppData,
    getDropdownOptForCanApp,
    setIsCompleteAppInfoVisible
  } = useContext(MyContext);
  useEffect(() => {
    // getOnBoardAppDets(appIds);
    getDropdownOptForCanApp();
    getCandidateOtherDetails();
  }, []);
  return (
    <section>
      <FormNav />
      {loading ? (
        <>
          <Skeleton variant="rectangular" animation="wave" width="100%" height={100} />
          <Skeleton variant="rectangular" animation="wave" width="100%" height={400} />
          <Skeleton variant="rectangular" animation="wave" width="100%" height={400} />
        </>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={otherDets}
          onSubmit={async (value) => {
            const formatedEducation = value?.educationDetails?.map((item) => ({
              ...item,
              stream: item?.stream?.value || item?.stream,
              degree: item?.degree?.value || item?.degree,
            }));
            const formatedEmergencyContactDetails = value?.emergencyContactDetails?.map((item) => ({
              ...item,
              relation: item?.relation?.value,
            }));
            const { personalDetails } = value;
            const hobbies = personalDetails.hobbies?.map((item) => item.value);
            const languages = personalDetails.languages?.map((item) => item.value);
            const formatedPersonalDetails = {
              ...personalDetails,
              bloodType: personalDetails.bloodType?.value,
              hobbies,
              languages,
            }
            const updatedValue = {
              ...value,
              educationDetails: formatedEducation,
              personalDetails: formatedPersonalDetails,
              emergencyContactDetails: formatedEmergencyContactDetails,
            }
            setSpineersLoader(true);
            const saveOtherDetailsResponse = await saveOtherDetails(updatedValue);
            if (saveOtherDetailsResponse.success) {
              if (saveOtherDetailsResponse.message) {
                showSnackbar(saveOtherDetailsResponse.message, {
                  variant: 'success',
                });
                setIsCompleteAppInfoVisible(false);
              }
            } else {
              showSnackbar(saveOtherDetailsResponse.message, {
                variant: 'error',
              });
            }
          }}
          validationSchema={otherDetailsValidationSchema}
        >
          {(props) => {
            setUnSaveAppData(props.values);
            return (
              <Form className="flex">
                <BankDetailsForm formController={props} />
                <EducationDetailsForm formController={props} />
                <CoursesDetailsForm formController={props} />
                <EmergencyContactDetailsForm formController={props} />
                <AdditionalDetailsForm formController={props} />
                <FormFooterButtons />
              </Form>
            );
          }}
        </Formik>
      )
      }
    </section >
  );
};

export default OtherDetailsPage;
