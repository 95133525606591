import {
  FromTabNumber,
  FromTabTitle,
  FromTabWrap,
  Tab,
  TabIcon,
} from '../../../style/components/Tab.styled';
import { Flex } from '../../../style/utils/Flex.styled';
import { FormTabState } from './statistics/FormTabState';
import { H4, H5 } from '../../../style/elements/Heading.style';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { MyContext } from '../../context/Context';
import { useLocation, useNavigate } from 'react-router-dom';

const FormTab = FormTabState;
export const FormNav = () => {
  const {
    appStatus,
    appSubmitCount,
    unSavedAppData,
    personalDets,
    professionalDets,
    otherDets,
    isCompleteAppInfoVisible
  } = useContext(MyContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [nextPath, setNextPath] = useState("");
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const handleRedirect = (cPath) => {
    setNextPath(cPath);
    let isUnsaved = false;

    if (cPath !== "/personal-details" && pathname === "/personal-details" && JSON.stringify(personalDets) !== JSON.stringify(unSavedAppData)) {
      isUnsaved = true;
    }

    if (cPath !== "/professional-details" && pathname === "/professional-details" && JSON.stringify(professionalDets) !== JSON.stringify(unSavedAppData)) {
      isUnsaved = true;
    }

    if (cPath !== "/other-details" && pathname === "/other-details" && JSON.stringify(otherDets) !== JSON.stringify(unSavedAppData)) {
      isUnsaved = true;
    }

    if (isUnsaved) {
      setIsDialogOpen(true);
    } else {
      navigate(cPath);
    }
  };

  const handleDiscard = () => {
    navigate(nextPath);
    setIsDialogOpen(false);
  }
  const handleCancel = () => {
    setIsDialogOpen(false);
  };
  return (
    <>
      <FromTabWrap>
        {FormTab.map((item) => {
          const {
            path,
            key,
            icon,
            title,
          } = item;
          const isActive = pathname === path;
          return (
            <Tab
              // to={path}
              isActive={isActive}
              key={key}
              onClick={() => handleRedirect(path)}
            >
              <FromTabNumber>
                <H5>{FormTab.indexOf(item) + 1}</H5>
              </FromTabNumber>
              <FromTabTitle>
                <TabIcon>{icon}</TabIcon>
                <H4 className="hide">{title}</H4>
              </FromTabTitle>
            </Tab>
          );
        })}
      </FromTabWrap>
      {appStatus === "started" && appSubmitCount !== 0 && isCompleteAppInfoVisible && (
        <Alert
          severity="warning"
        // variant="outlined"
        >
          Your details have been updated. Please "Submit" your application from Other Details to complete the process
        </Alert>
      )}
      <Dialog
        open={isDialogOpen}
        // onClose={handleCancel}
        onClose={(event, resone) => {
          if (resone !== "backdropClick") {
            handleCancel();
          }
        }}
        aria-labelledby="unsaved-changes-dialog"
        PaperProps={{
          style: {
            borderRadius: "12px",
            padding: "16px",
            backgroundColor: "#f9f9f9",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <DialogTitle
          id="unsaved-changes-dialog"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            fontWeight: "600",
            fontSize: "1.2rem",
            color: "#333",
          }}
        >
          <Typography component="span" style={{ color: "#f64f39" }}>
            ⚠️
          </Typography>
          Unsaved Changes
        </DialogTitle>
        <DialogContent style={{ marginTop: "8px" }}>
          <Typography
            style={{
              fontSize: "1rem",
              color: "#555",
              marginBottom: "20px",
            }}
          >
            You have unsaved changes on this page. Are you sure you want to leave without saving?
          </Typography>
          <Typography
            style={{
              fontSize: "0.9rem",
              color: "#888",
              marginBottom: "16px",
            }}
          >
            Any changes you made will be lost if you proceed.
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "space-between",
            padding: "16px",
            gap: "8px",
          }}
        >
          <Button
            onClick={handleDiscard}
            variant="contained"
            color="error"
            style={{
              backgroundColor: "#f64f39",
              color: "#fff",
              textTransform: "capitalize",
              fontWeight: "500",
            }}
          >
            Discard Changes
          </Button>
          <Button
            onClick={handleCancel}
            variant="outlined"
            style={{
              textTransform: "capitalize",
              fontWeight: "500",
              borderColor: "#59B2f4",
              color: "#59B2f4",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};