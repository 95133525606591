import moment from 'moment';
import { ViewField } from '../controls/ViewField';
import { ViewDividerWithTitle } from '../controls/ViewDividerWithTitle';
import { ImagePopup } from '../../../components/img/ImagePopup';
import {
  ViewDetailsContent,
  ViewDetailsContentItems,
  ViewDetailsContentWithImg,
  ViewDetailsItem,
  ViewDetailsItemOne,
  ViewDetailsWrap,
} from '../../../../style/components/ApplicationView.styled';

/*****
  * ## capitalizeFirstChar
 * @author Sagar Dodiya - `previous`
  * @author hitesh kanjariya - `current`
  */
const capitalizeFirstChar = (str) => str?.charAt(0).toUpperCase() + str?.slice(1);

const PersonalDetailsView = ({ details }) => {
  return (
    <ViewDetailsWrap>
      {/* 1) Personal details */}
      <ViewDetailsContentWithImg>
        <ImagePopup
          img={details.candidatePhoto}
          alt={'candidate avatar'}
          variant="avatar"
          skeletonVariant={'circular'}
          skeletonRatio={180}
          css="image-aspect-ratio"
        />

        <ViewDetailsItemOne>
          <ViewField name={'Firstname'} value={capitalizeFirstChar(details.firstname)} />
          <ViewField name={'Lastname'} value={capitalizeFirstChar(details.lastname)} />
          <ViewField name={'Gender'} value={capitalizeFirstChar(details.gender)} />
          <ViewField name={'Email'} value={details.email} />
          <ViewField name={'Contact No.'} value={details.contactNo} />
          <ViewField name={'Nationality'} value={capitalizeFirstChar(details.nationality)} />
          <ViewField
            name={'Date of Birth'}
            value={details.birthDate && moment(details.birthDate).format('LL')}
          />
          <ViewField name={'Age'} value={details.age} />
        </ViewDetailsItemOne>
      </ViewDetailsContentWithImg>

      {/* 2) Address */}
      <ViewDetailsContent>
        <ViewDetailsContentItems>
          <ViewDividerWithTitle title={'Permanent Address'} />
          <ViewDetailsItem>
            <ViewField
              css="col-span-two"
              name={'Street'}
              value={capitalizeFirstChar(details.permanentStreet)}
            />
            <ViewField name={'City'} value={capitalizeFirstChar(details.permanentCity)} />
            <ViewField name={'Pincode'} value={capitalizeFirstChar(details.permanentPincode)} />
            <ViewField name={'State'} value={capitalizeFirstChar(details.permanentState)} />
            <ViewField name={'Country'} value={capitalizeFirstChar(details.permanentCountry)} />
          </ViewDetailsItem>
        </ViewDetailsContentItems>
        <ViewDetailsContentItems>
          <ViewDividerWithTitle title={'Communication Address'} />
          <ViewDetailsItem>
            <ViewField
              css="col-span-two"
              name={'Street'}
              value={capitalizeFirstChar(details.communicationStreet)}
            />
            <ViewField name={'City'} value={capitalizeFirstChar(details.communicationCity)} />
            <ViewField name={'Pincode'} value={capitalizeFirstChar(details.communicationPincode)} />
            <ViewField name={'State'} value={capitalizeFirstChar(details.communicationState)} />
            <ViewField name={'Country'} value={capitalizeFirstChar(details.communicationCountry)} />
          </ViewDetailsItem>
        </ViewDetailsContentItems>
      </ViewDetailsContent>
    </ViewDetailsWrap>
  );
};

export default PersonalDetailsView;
