import moment from 'moment';
import { ViewField } from '../controls/ViewField';
import { ViewDividerWithTitle } from '../controls/ViewDividerWithTitle';
import { ImagePopup } from '../../../components/img/ImagePopup';
import {
  ViewDetailsContent,
  ViewDetailsContentItems,
  ViewDetailsContentWithImg,
  ViewDetailsItem,
  ViewDetailsItemOne,
  ViewDetailsWrap,
} from '../../../../style/components/ApplicationView.styled';
import { Avatar, Box, Link, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useContext } from 'react';
import { MyContext } from '../../../context/Context';


/*****
  * ## capitalizeFirstChar
 * @author Sagar Dodiya - `previous`
  * @author hitesh kanjariya - `current`
  */
const capitalizeFirstChar = (str) => str?.charAt(0).toUpperCase() + str?.slice(1);
const getAadharcardLabel = (name, index, arr) => {
  switch (arr.length) {
    case 1:
      return "Aadhar card";
    case 2:
      return index === 0 ? "Aadhar card front" : "Aadhar card back";
    default:
      return "Aadhar";
  }
};

const PersonalDetailsView = ({ details }) => {
  const { professionalDets, otherDets } = useContext(MyContext)
  const { experianceDetails } = professionalDets;
  const { bankDetails } = otherDets;
  return (
    <ViewDetailsWrap>
      {/* 1) Personal details */}
      <ViewDetailsContentWithImg>
        <Link href={details.candidatePhoto} underline="none" target="_blank" rel='noreferrer noopener'>
          <div className="candidate-profile-image-div">
            <img
              src={details.candidatePhoto}
              alt="candidate avatart"
              className="canidate-dis-profile-image"
            />
          </div>
        </Link>
        <ViewDetailsItemOne>
          <ViewField name={'Firstname'} value={capitalizeFirstChar(details.firstname)} />
          <ViewField name={'Lastname'} value={capitalizeFirstChar(details.lastname)} />
          <ViewField name={'Gender'} value={capitalizeFirstChar(details.gender)} />
          <ViewField name={'Email'} value={details.email} />
          <ViewField name={'Contact No.'} value={details.contactNo} />
          <ViewField name={'Nationality'} value={capitalizeFirstChar(details.nationality)} />
          <ViewField
            name={'Date of Birth'}
            value={details.birthDate && moment(details.birthDate).format('LL')}
          />
          <ViewField name={'Age'} value={details.age} />

        </ViewDetailsItemOne>
        <div style={{ alignSelf: "flex-start" }}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography variant='h6'>
              All Documents
            </Typography>
            <List>
              {details?.passportPhoto && (
                <Link href={details?.passportPhoto} target="_blank" rel='noreferrer noopener'>
                  <ListItem sx={{ padding: "4px" }}>
                    <ListItemText>
                      Passport image
                    </ListItemText>
                  </ListItem>
                </Link>
              )}
              {details?.aadharImages?.map((item, index, arr) => (
                <Link href={item} target="_blank" rel='noreferrer noopener'>
                  <ListItem key={item} sx={{ padding: "4px" }}>
                    <ListItemText
                      primary={
                        getAadharcardLabel(item, index, arr)
                      } />
                  </ListItem>
                </Link>
              ))}
              {experianceDetails && (
                experianceDetails?.map((item, index) => (
                  <>
                    <Link href={experianceDetails[index].experienceLetterUrl} target="_blank" rel='noreferrer noopener'>
                      {experianceDetails[index].experienceLetterUrl && (
                        <ListItem key={item} sx={{ padding: "4px" }}>
                          <ListItemText
                            primary={`Experience letter - ${index + 1}`}>
                          </ListItemText>
                        </ListItem>
                      )}
                    </Link >
                    <Link href={experianceDetails[index].relievingLetterUrl} target="_blank" rel='noreferrer noopener'>
                      {experianceDetails[index].relievingLetterUrl && (
                        <ListItem key={item} sx={{ padding: "4px" }}>
                          <ListItemText
                            primary={`Relieving letter - ${index + 1}`}>
                          </ListItemText>
                        </ListItem>
                      )}
                    </Link>
                    <Link href={experianceDetails[index].salarySlipUrl} target="_blank" rel='noreferrer noopener'>
                      {experianceDetails[index].salarySlipUrl && (
                        <ListItem key={item} sx={{ padding: "4px" }}>
                          <ListItemText
                            primary={`Salary slip - ${index + 1}`}>
                          </ListItemText>
                        </ListItem>
                      )}
                    </Link>
                  </>
                ))
              )}
              {bankDetails?.document && (
                <Link
                  href={bankDetails.document}
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Open Experience Letter"
                >
                  <ListItem key={bankDetails.document} sx={{ padding: "4px" }}>
                    <ListItemText>
                      Bank passbook / check
                    </ListItemText>
                  </ListItem>
                </Link>
              )}
            </List>
          </Box>
        </div>

      </ViewDetailsContentWithImg>

      {/* 2) Address */}
      <ViewDetailsContent>
        <ViewDetailsContentItems>
          <ViewDividerWithTitle title={'Permanent Address'} />
          <ViewDetailsItem>
            <ViewField
              css="col-span-two"
              name={'Street'}
              value={capitalizeFirstChar(details.permanentStreet)}
            />
            <ViewField name={'City'} value={capitalizeFirstChar(details.permanentCity)} />
            <ViewField name={'Pincode'} value={capitalizeFirstChar(details.permanentPincode)} />
            <ViewField name={'State'} value={capitalizeFirstChar(details.permanentState)} />
            <ViewField name={'Country'} value={capitalizeFirstChar(details.permanentCountry)} />
          </ViewDetailsItem>
        </ViewDetailsContentItems>
        <ViewDetailsContentItems>
          <ViewDividerWithTitle title={'Communication Address'} />
          <ViewDetailsItem>
            <ViewField
              css="col-span-two"
              name={'Street'}
              value={capitalizeFirstChar(details.communicationStreet)}
            />
            <ViewField name={'City'} value={capitalizeFirstChar(details.communicationCity)} />
            <ViewField name={'Pincode'} value={capitalizeFirstChar(details.communicationPincode)} />
            <ViewField name={'State'} value={capitalizeFirstChar(details.communicationState)} />
            <ViewField name={'Country'} value={capitalizeFirstChar(details.communicationCountry)} />
          </ViewDetailsItem>
        </ViewDetailsContentItems>

      </ViewDetailsContent>
    </ViewDetailsWrap >
  );
};

export default PersonalDetailsView;
