import React, { useContext, useState } from 'react';
import { Button, Typography, Box, IconButton, List, ListItem, ListItemText, ListItemAvatar, Avatar, ListItemSecondaryAction } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { MyContext } from '../../../context/Context';

function MultiFileUploadWithTypeIcons() {
  const { selectedAadharCardFiles, setSelectedAadharCardFiles } = useContext(MyContext);
  // const [selectedAadharCardFiles, setSelectedAadharCardFiles] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const oldSelectedAadharCard = [...selectedAadharCardFiles, ...files];
    // const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    const MAX_FILE_SIZE = 2 * 1024 * 1024;
    const MAX_IMAGE_COUNT = 2;

    let validationErrors = [];
    const validFiles = files.filter(file => {
      let isValid = true;
      // Check file size
      if (file.size > MAX_FILE_SIZE) {
        validationErrors.push(`File ${file.name} exceeds the 2MB size limit.`);
        isValid = false;
      }
      // Check for image file count limit
      if (oldSelectedAadharCard.length > MAX_IMAGE_COUNT) {
        validationErrors.push("You can only upload a maximum of 2 images.");
        isValid = false;
      }
      return isValid;
    });
    if (validationErrors.length) {
      setErrorMessages(validationErrors);
    } else {
      setErrorMessages([]);
    }
    const unMatchedFile = validFiles?.filter((item) => !selectedAadharCardFiles.find((file) => file.name === item.name));
    setSelectedAadharCardFiles(prevFiles => [...prevFiles, ...unMatchedFile]);
  };

  const handleFileDelete = (fileName) => {
    setSelectedAadharCardFiles(prevFiles => prevFiles.filter(fileObj => fileObj.name !== fileName));
    setErrorMessages("")
  };

  const getFileIcon = (file) => {
    if (file?.type === 'application/pdf') {
      return <PictureAsPdfIcon />;
    }
    if (file?.type.includes('image')) {
      return <ImageIcon />
    }
    return <InsertDriveFileIcon />;
  };


  const handleImagePreview = (name) => {
    const getFile = selectedAadharCardFiles?.filter((item) => item.name === name);
    if (getFile.length > 0) {
      const createImageUrl = URL.createObjectURL(getFile[0]);
      // Open the image URL in a new tab
      window.open(createImageUrl, '_blank');
    }
  };
  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      <Button
        variant="contained"
        component="label"
        color="primary"
        startIcon={<CloudUploadIcon />}
      >
        Aaddhar Card
        <input
          type="file"
          accept=".jpg,.jpeg,.png,.pdf"
          hidden
          multiple
          onChange={handleFileChange}
        />
      </Button>

      <List>
        {selectedAadharCardFiles.map((fileObj, index) => (
          <ListItem key={index} divider >
            <ListItemAvatar>
              <Avatar onClick={() => handleImagePreview(fileObj?.name)}>
                {getFileIcon(fileObj.file)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={<span style={{ textTransform: 'capitalize' }}>{fileObj?.name}</span>}
              onClick={() => handleImagePreview(fileObj?.name)}
            />
            <ListItemSecondaryAction>
              <IconButton onClick={() => handleFileDelete(fileObj?.name)} color="error">
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      {errorMessages.length > 0 && (
        <Box>
          <Typography variant="body2" color="error">
            {errorMessages[errorMessages.length - 1]}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default MultiFileUploadWithTypeIcons;
