import { useContext, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  FormHelperText,
  TextField,
  Box,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid } from '../../../../style/utils/Grid.styled';
import { MyContext } from '../../../context/Context';
import {
  FormWrap,
  FromPersonalContent,
  FormContentInputDiv,
} from '../../../../style/components/form/StyledForm.styled';
import { ImagePopup } from '../../../components/img/ImagePopup';
import ErrorMessage from '../../../components/message/ErrorMessage';
import showSnackbar from '../../../components/message/showSnackbar';
import CustomTextField from '../../../components/form/formControl/TextField';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import "../css/personalDetails.css";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import AaddharCardUpload from './AaddharCardUpload';

const PersonalDetailsForm = ({ formController }) => {
  const { candidasteAvatar, imageValidation, setAvatar, setCandidatePassportPhoto } = useContext(MyContext);
  const {
    values,
    handleChange,
    setFieldValue,
    touched,
    errors,
    setErrors,
  } = formController;
  const [profileImageError, setProfileImageError] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [passportImageError, setPassportImageError] = useState("");
  const [passportImageUrl, setPassportImageUrl] = useState("");

  // Function to handle profile image upload and update
  const handleProfileImage = async (event) => {
    const file = event.target.files[0];
    // setProfileImageFile(file);
    const allowedFileType = ["image/jpeg", "image/png"];
    if (file && !allowedFileType.includes(file.type)) {
      setProfileImageError("Please select a JPEG or PNG.");
      return;
    }
    const maxSize = 2 * 1024 * 1024;
    if (file && file.size > maxSize) {
      setProfileImageError("Profile size exceeds the maximum limit (2MB).");
      return;
    }
    if (file) {
      setProfileImageUrl(URL.createObjectURL(file));
      setAvatar(file);
      setProfileImageError("");
    }
  };

  const handleChangePassport = (event) => {
    const file = event.target.files[0];
    const allowFileType = ["image/jpeg", "image/png"];
    if (file && !allowFileType.includes(file.type)) {
      setPassportImageError("Please select a JPEG or PNG");
      return;
    }
    const maxSize = 2 * 1024 * 1024;
    if (file && file.size > maxSize) {
      setPassportImageError("Passport size exceeds the maximum limit (2MB");
      return;
    }
    if (file) {
      setPassportImageUrl(URL.createObjectURL(file))
      setCandidatePassportPhoto(file);
      setPassportImageError("");
    }
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  return (
    <FormWrap>
      <Typography variant="h6">Personal Details</Typography>
      <FromPersonalContent>
        <div className="profile-container first-row">
          <div className="profile-image-div">
            <a href={profileImageUrl || values?.candidatePhoto}
              target="_blank"
              rel="noopener norefere">
              <img
                src={profileImageUrl || values?.candidatePhoto}
                alt="Profile Image"
                className="profile-image"
              />
            </a>
            <label htmlFor="profile_image_input" className="profile_image_label">
              <div className="edit-icon">
                <EditOutlinedIcon />
              </div>
            </label>
          </div>
          <input
            id="profile_image_input"
            type="file"
            hidden
            onChange={(event) => handleProfileImage(event)}
          />
          <div className="error-message">
            {profileImageError && profileImageError}
          </div>
        </div>
        <CustomTextField
          id="Firstname"
          label="Firstname"
          name="firstname"
          value={values.firstname}
          onChange={handleChange}
          errors={errors.firstname}
          touched={touched.firstname}
          variant="outlined"
        />
        <CustomTextField
          label="Lastname"
          name="lastname"
          value={values.lastname}
          onChange={handleChange}
          errors={errors.lastname}
          touched={touched.lastname}
          variant="outlined"
        />
        <FormContentInputDiv>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date of Birth"
              inputFormat="dd/MM/yyyy"
              name="birthDate"
              value={values.birthDate}
              onChange={(val) => {
                setFieldValue('birthDate', val);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    // error={touched.birthDate && Boolean(errors.birthDate)}
                    // helperText={touched.birthDate && errors.birthDate}
                    errors={errors.birthDate}
                  />
                );
              }}
            />
          </LocalizationProvider>
          <ErrorMessage message={touched.birthDate && errors.birthDate} />
        </FormContentInputDiv>
        <CustomTextField
          type="number"
          label="Age"
          name="age"
          value={values.age}
          onChange={handleChange}
          errors={errors.age}
          touched={errors.age}
          variant="outlined"
        />
        <CustomTextField
          disabled
          label="Email"
          name="email"
          value={values.email}
          onChange={handleChange}
          errors={errors.email}
          touched={touched.email}
          variant="outlined"
        />
        <CustomTextField
          type="number"
          label="Contact No."
          name="contactNo"
          value={values.contactNo}
          onChange={handleChange}
          touched={touched.contactNo}
          errors={errors.contactNo}
          variant="outlined"
        />
        <CustomTextField
          label="Nationality"
          name="nationality"
          value={values.nationality}
          onChange={handleChange}
          touched={touched.nationality}
          errors={errors.nationality}
          variant="outlined"
        />
        <div className="col-span-two">
          <FormContentInputDiv>
            <FormControl>
              <FormLabel>Gender</FormLabel>
              <RadioGroup
                name="gender"
                value={values.gender}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
                {/* {touched.gender && Boolean(errors.gender) && (
              <FormHelperText error>{errors.gender}</FormHelperText>
            )} */}
              </RadioGroup>
            </FormControl>
            <ErrorMessage message={touched.gender && errors.gender} />
          </FormContentInputDiv>
        </div>
        <div>
          <FormControl>
            <Box display="flex" flexDirection="column" gap={2}>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                sx={2}
              >
                Passport Photo
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleChangePassport}
                  accept='.jpg,.jpeg,.png'
                />
              </Button>
              <a
                href={passportImageUrl || values?.passportPhoto}
                target='_blank'
                rel="noopener norefere"
              >
                <img
                  src={passportImageUrl || values?.passportPhoto}
                  alt=""
                  className="passport-image"
                />
              </a>
            </Box>
            <div className="error-message">
              {passportImageError && passportImageError}
            </div>
          </FormControl>
        </div>
        <div>
          <AaddharCardUpload />
        </div>
        {/* <Grid colSpan={'1 / span 3'}>
          <FormControl>
            <FormLabel
              sx={{
                color: errors && errors.avatarAttachment && 'error.main',
              }}
            >
              {errors && errors.avatarAttachment && errors.avatarAttachment}
              {candidasteAvatar && errors && !errors.avatarAttachment && (
                <span className="avatar-thumbnail-span">
                  <ImagePopup
                    img={URL.createObjectURL(candidasteAvatar)}
                    alt={'profile document'}
                    skeletonVariant="rectangular"
                    css="image-thumbnail-2"
                  />
                  Click on image for full screen view.
                </span>
              )}
              {!candidasteAvatar &&
                values.candidatePhoto &&
                errors &&
                !errors.avatarAttachment && (
                  <span className="avatar-thumbnail-span">
                    <ImagePopup
                      img={values.candidatePhoto}
                      alt={'profile document'}
                      skeletonVariant="rectangular"
                      css="image-thumbnail-2"
                    />
                    Click on image for full screen view.
                  </span>
                )}
            </FormLabel>
            <Button
              variant="contained"
              component="label"
              sx={{ maxWidth: '222px', mt: '0.8rem' }}
              disableElevation
              onBlur={() => {
                if (errors && errors.avatarAttachment) {
                  showSnackbar(errors.avatarAttachment, {
                    variant: 'error',
                  });
                  setErrors({ ...errors, avatarAttachment: undefined });
                  setFieldValue('avatarAttachment', '');
                  setAvatar();
                }
              }}
            >
              Upload Profile
              <input
                hidden
                name="avatarAttachment"
                id="avatarAttachment"
                type="file"
                onChange={(e) => {
                  setFieldValue('avatarAttachment', e.target.files[0]);
                  // setAvatar(e.target.files[0]);
                  setCandidatePassportPhoto(e.target.files[0]);
                }}
              />
            </Button>
          </FormControl>
        </Grid> */}
        <Grid>

        </Grid>
      </FromPersonalContent>

    </FormWrap>
  );
};

export default PersonalDetailsForm;