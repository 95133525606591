import * as Yup from 'yup';

const professionalDetailsValidationSchema = Yup.object({
  personalDetails: Yup.object({
    experianceStatus: Yup.string().required('experiance status is required'),
  }),
  experianceDetails: Yup.array().when('personalDetails.experianceStatus', {
    is: 'experienced',
    then: Yup.array(
      Yup.object().shape({
        companyName: Yup.string()
          .required('Company name is required')
          .matches(/\S+/, 'Company name cannot be blank'),
        jobTitle: Yup.string()
          .required('Job title is required')
          .matches(/\S+/, 'Job title cannot be blank'),
        yearOfExperiance: Yup.string()
          .required('Year of experience is required')
          .matches(/\S+/, 'Year of experience cannot be blank')
          .matches(/^[0-9]+$/, 'Please enter a valid experience.'),
        city: Yup.string()
          .required('City is required')
          .matches(/\S+/, 'City cannot be blank'),
        joinningDate: Yup.date()
          .required('Joining date is required')
          .nullable()
          .max(new Date(), 'Joining date cannot be in the future'),
        tillDate: Yup.date()
          .required('Till date is required')
          .nullable()
          .min(Yup.ref("joinningDate"), "Till date cannot be before Joining Date")
          .max(new Date(), 'Till date cannot be in the future.'),
      })
    ),
  }),
  skillSet: Yup.object({
    skills: Yup.array()
      .min(3, 'At least 3 skills are required.')
      .required(),
    tools: Yup.array()
      .min(1, 'At least 1 tool is required.')
      .required(),
  }),
});
export default professionalDetailsValidationSchema;
