import { Form } from 'formik';
import styled from 'styled-components';

const AuthContainer = styled.div`
display: grid;
grid-template-columns: 30% auto;

@media only screen and (max-width: 1200px) {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}
`;

const AuthItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: ${({ bg }) => (bg ? 'white' : null)};

  img {
    max-width: 300px;
  }

  @media only screen and (max-width: 1200px) {
    width:auto;
    height:auto;
    background-color: transparent !important;
    gap:10px;
  }
    @media only screen and (max-width: 600px) {
    img {
    max-width: 200px;
  }
})
`;

const AUthForm = styled(Form)`
 
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  max-width: 500px;
  @media only screen and (max-width: 800px) {
    gap: 2rem;
    padding: 2rem;
  }
  @media only screen and (max-width: 800px) {
    gap: 2rem;
    padding: 1.5rem;
  }
`;

const AuthTypo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .login-title {
    position: relative;
    font-size: 3rem;
  }
  .login-title::before {
    content: '';
    position: absolute;
    inset: 100% 0;
    width: 20%;
    height: 6px;
    background-image: linear-gradient(to right, #4481eb 0%, #04befe 100%);
    transform: skew(45deg);
    border-radius: 20px;
  }
  .login-subtitle {
    font-size: 1.2rem;
  }
  @media only screen and (max-width: 800px) {
    gap: 1rem;
    .login-title {
      position: relative;
      font-size: 2.5rem;
    }
    .login-subtitle {
      font-size: 1rem;
    }
  }
`;

const AuthInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  align-items: flex-end;

  & > div {
    width: 100%;
  }
  
  & > a {
    color: rgb(0, 125, 184);
  }
`;

// const SlideLeftAnimation = styled.span`
//   animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
//   @keyframes slide-left {
//     0% {
//       transform: translateX(0);
//     }
//     100% {
//       transform: translateX(0);
//     }
//   }
// `;

export {
  AuthContainer,
  AuthItem,
  AUthForm,
  AuthTypo,
  AuthInputs,
  // SlideLeftAnimation,
};
