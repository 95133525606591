import React, { useContext, useEffect, useState } from 'react';
import { FieldArray } from 'formik';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import AutoTextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import DeleteIcon from '@mui/icons-material/Delete';
import { has, isEmpty } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid } from '../../../../style/utils/Grid.styled';
import { EducationDetailsInit } from '../statistics/otherDetailsInitialValues';
import { FormContentInputDiv, FormWrap, FromContent } from '../../../../style/components/form/StyledForm.styled';
import ErrorMessage from '../../../components/message/ErrorMessage';
import CustomTextField from '../../../components/form/formControl/TextField';
import showSnackbar from '../../../components/message/showSnackbar';
import {
  addDegreeApi,
  addStreamApi,
  deleteEducation,
  fetchDegreesApi,
  fetchStreamApi,
} from '../../../../api/apis';
import { MyContext } from '../../../context/Context';

const EducationDetailsForm = () => {
  // State to control the visibility of the delete confirmation dialog
  const [eduDelDiaOpen, setEduDelDiaOpen] = useState(false);

  // State to store the ID of the experience to be deleted
  const [delEduId, setEduDelId] = useState(null);
  const [degrees, setDegrees] = useState([]);
  const [streams, setStreams] = useState([]);


  // Extracting the getProfessionalDetails function from the context
  const {
    getCandidateOtherDetails,
  } = useContext(MyContext);

  const fetchStreams = async () => {
    try {
      const res = await fetchStreamApi();
      if (res.status === 200) {
        setStreams(res.data.data)
      } else {
        setStreams([]);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
        autoHideDuration: 5000,
      })
    }
  }

  const fetchDegrees = async () => {
    try {
      const res = await fetchDegreesApi();
      if (res.status === 200) {
        setDegrees(res.data.data);
      } else {
        setDegrees([]);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
        autoHideDuration: 5000
      })
    }
  }

  useEffect(() => {
    fetchStreams();
    fetchDegrees();
  }, []);

  const handleAddDegree = async (value) => {
    try {
      const res = await addDegreeApi(value);
      if (res.status === 201) {
        const { data } = res.data;
        setDegrees((preval) => [...preval, data]);
        setValue(data)
      }
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
        autoHideDuration: 5000
      })
    }
  }

  // Function to handle the initiation of the delete process
  const handleDelEducation = (id) => {
    // Set the ID of the experience to be de type=""leted
    setEduDelId(id);
    // Open the delete confirmation dialog
    setEduDelDiaOpen(true);
  };

  // Function to handle the confirmation of the delete action
  const handleDelEducationConfirm = async () => {
    // Call the API to delete
    const apiRes = await deleteEducation(delEduId);
    // Destructure the API response
    const { success, message } = apiRes;

    if (success) {
      // If the deletion was successful
      // Show a success message
      showSnackbar(message, { variant: 'success' });
      // Close the delete confirmation dialog
      setEduDelDiaOpen(false);
      // Refresh the professional details
      getCandidateOtherDetails();
    } else {
      // If the deletion failed
      // Show an error message
      showSnackbar(message, { variant: 'error' });
    }
  };

  // Function to handle the cancellation of the delete action
  const handleDelEduCancel = () => {
    // Close the delete confirmation dialog without deleting
    setEduDelDiaOpen(false);
  };

  const [value, setValue] = React.useState(null);
  const filter = createFilterOptions();

  useEffect(() => {
    fetchStreams();
    fetchDegrees();
  }, []);
  return (
    <FormWrap>
      <Typography variant="h6">Education Details</Typography>
      <FieldArray name="educationDetails">
        {(props) => {
          const { remove, push, form } = props;
          const {
            values,
            handleChange,
            setFieldValue,
            errors,
            touched,
          } = form;
          const { educationDetails } = values;
          return (
            <Grid gap={'1rem'}>
              {educationDetails !== undefined &&
                educationDetails !== null &&
                educationDetails?.length > 0
                ? educationDetails.map((add, index) => {
                  return (
                    <Card variant="outlined" key={index}>
                      <CardActions
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography gutterBottom>Details</Typography>
                        {educationDetails?.length !== 1 && (
                          <IconButton
                            onClick={() => {
                              const delId = educationDetails[index]?.id;
                              if (delId) {
                                handleDelEducation(delId);
                              } else {
                                remove(index);
                              }
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </CardActions>
                      <CardContent>
                        <FromContent>
                          <FormControl>
                            <FormContentInputDiv>
                              <Autocomplete
                                name={`educationDetails.${index}.degree`}
                                value={values.educationDetails[index].degree}
                                onChange={async (event, newValue) => {
                                  if (newValue && newValue.inputValue) {
                                    const res = await addDegreeApi({ name: newValue.inputValue });
                                    if (res.status === 201) {
                                      const { data } = res.data;
                                      setDegrees((preval) => [...preval, data]);
                                      setFieldValue(`educationDetails.${index}.degree`, data);
                                    }
                                  } else {
                                    setFieldValue(`educationDetails.${index}.degree`, newValue);
                                  }
                                }}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  const { inputValue } = params;
                                  // Suggest the creation of a new value
                                  const isExisting = options.some((option) => inputValue === option.label);
                                  if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                      inputValue,
                                      label: `Add "${inputValue}"`,
                                    });
                                  }
                                  return filtered;
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id="Degree"
                                options={degrees}
                                getOptionLabel={(option) => {
                                  // Value selected with enter, right from the input
                                  if (typeof option === 'string') {
                                    return option;
                                  }
                                  // Add "xxx" option created dynamically
                                  if (option.inputValue) {
                                    return option.inputValue;
                                  }
                                  // Regular option
                                  return option.label;
                                }}
                                renderOption={(props, option) => {
                                  const { key, ...optionProps } = props;
                                  return (
                                    <li key={key} {...optionProps}>
                                      {option.label}
                                    </li>
                                  );
                                }}
                                // sx={{ width: 300 }}
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Degree"
                                    error={Boolean(errors?.educationDetails?.[index]?.degree && touched?.educationDetails?.[index]?.degree)}
                                  />
                                )}
                              />
                              <ErrorMessage
                                message={
                                  touched.educationDetails?.[index]?.degree
                                  && errors.educationDetails?.[index]?.degree
                                }
                              />
                            </FormContentInputDiv>
                          </FormControl>
                          <FormControl>
                            <FormContentInputDiv>
                              <Autocomplete
                                name={`educationDetails.${index}.stream`}
                                value={values.educationDetails[index].stream}
                                onChange={async (event, newValue) => {
                                  if (newValue && newValue.inputValue) {
                                    const res = await addStreamApi({ name: newValue.inputValue });
                                    if (res.status === 201) {
                                      const { data } = res.data;
                                      setStreams((preval) => [...preval, data]);
                                      setFieldValue(`educationDetails.${index}.stream`, data);
                                    }
                                  } else {
                                    setFieldValue(`educationDetails.${index}.stream`, newValue);
                                  }
                                }}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  const { inputValue } = params;
                                  // Suggest the creation of a new value
                                  const isExisting = options.some((option) => inputValue === option.label);
                                  if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                      inputValue,
                                      label: `Add "${inputValue}"`,
                                    });
                                  }
                                  return filtered;
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id="Stream"
                                options={streams}
                                getOptionLabel={(option) => {
                                  // Value selected with enter, right from the input
                                  if (typeof option === 'string') {
                                    return option;
                                  }
                                  // Add "xxx" option created dynamically
                                  if (option.inputValue) {
                                    return option.inputValue;
                                  }
                                  // Regular option
                                  return option.label;
                                }}
                                renderOption={(props, option) => {
                                  const { key, ...optionProps } = props;
                                  return (
                                    <li key={key} {...optionProps}>
                                      {option.label}
                                    </li>
                                  );
                                }}
                                // sx={{ width: 300 }}
                                freeSolo
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Stream"
                                    error={Boolean(errors?.educationDetails?.[index]?.stream && touched?.educationDetails?.[index]?.stream)}
                                  />
                                )}
                              />
                              <ErrorMessage
                                message={
                                  touched.educationDetails?.[index]?.stream
                                  && errors.educationDetails?.[index]?.stream
                                }
                              />
                            </FormContentInputDiv>
                          </FormControl>

                          <CustomTextField
                            label="Institute"
                            name={`educationDetails.${index}.institute`}
                            value={values.educationDetails[index].institute}
                            onChange={handleChange}
                            errors={touched.educationDetails?.[index]?.institute && errors.educationDetails?.[index]?.institute}
                            touched={touched.educationDetails?.[index]?.institute}
                            variant="outlined"
                          />
                          <CustomTextField
                            label="Board/University"
                            name={`educationDetails.${index}.boardOrUniversity`}
                            value={values.educationDetails[index].boardOrUniversity}
                            onChange={handleChange}
                            errors={touched.educationDetails?.[index]?.boardOrUniversity && errors.educationDetails?.[index]?.boardOrUniversity}
                            touched={touched.educationDetails?.[index]?.boardOrUniversity}
                            variant="outlined"
                          />
                          <CustomTextField
                            label="GPA/ Percentage"
                            name={`educationDetails.${index}.gpaOrPercentage`}
                            value={values.educationDetails[index].gpaOrPercentage}
                            onChange={handleChange}
                            errors={touched.educationDetails?.[index]?.gpaOrPercentage && errors.educationDetails?.[index]?.gpaOrPercentage}
                            touched={touched.educationDetails?.[index]?.gpaOrPercentage}
                            variant="outlined"
                          />
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormContentInputDiv>
                              <DatePicker
                                label="Passig Year"
                                inputFormat="dd/MM/yyyy"
                                name={`educationDetails.${index}.passingYear`}
                                value={values.educationDetails[index].passingYear}
                                onChange={(val) => {
                                  setFieldValue(`educationDetails.${index}.passingYear`, val);
                                }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      // error={
                                      //   !isEmpty(touched) && !isEmpty(errors) &&
                                      //   has(errors, 'educationDetails') &&
                                      //   !isEmpty(touched.educationDetails[index]) &&
                                      //   touched.educationDetails[index].passingYear &&
                                      //   !isEmpty(errors.educationDetails[index]) &&
                                      //   Boolean(errors.educationDetails[index].passingYear)
                                      // }
                                      error={touched.educationDetails?.[index]?.passingYear && Boolean(errors.educationDetails?.[index]?.passingYear)}

                                    />
                                  );
                                }}
                              />
                              <ErrorMessage
                                // message={
                                //   !isEmpty(touched) && !isEmpty(errors) &&
                                //   has(errors, 'educationDetails') &&
                                //   !isEmpty(touched.educationDetails[index]) &&
                                //   touched.educationDetails[index].passingYear &&
                                //   !isEmpty(errors.educationDetails[index]) &&
                                //   errors.educationDetails[index].passingYear
                                // }
                                message={touched.educationDetails?.[index]?.passingYear && errors.educationDetails?.[index]?.passingYear}
                              />
                            </FormContentInputDiv>
                          </LocalizationProvider>
                        </FromContent>
                      </CardContent>
                    </Card>

                  );
                })
                : null}
              <Grid jc={'center'}>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  sx={{
                    borderRadius: '50%',
                    aspectRatio: '1/1',
                    minWidth: '0.2rem',
                    padding: '0.5rem',
                  }}
                >
                  <AddIcon onClick={() => push(EducationDetailsInit)} />
                </Button>
              </Grid>
            </Grid>
          );
        }}
      </FieldArray>
      <Dialog open={eduDelDiaOpen} onClose={handleDelEduCancel}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this item?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelEduCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelEducationConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </FormWrap>
  );
};

export default EducationDetailsForm;
