import * as Yup from 'yup';
const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

const experienceLetterValidation = Yup.mixed()
  .required("Experience Letter is required.")
  .test("fileSize", "File size is too large. Maximum size is 5MB.", (value) => {
    return value && value.size <= 5 * 1024 * 1024; // 5MB
  })
  .test("fileType", "Only .jpg, .jpeg, .png, and .pdf files are allowed.", (value) => {
    return value && ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
  });

const professionalDetailsValidationSchema = Yup.object({
  personalDetails: Yup.object({
    experianceStatus: Yup.string().required('experiance status is required'),
  }),
  experianceDetails: Yup.array().when('personalDetails.experianceStatus', {
    is: 'experienced',
    then: Yup.array(
      Yup.object().shape({
        companyName: Yup.string()
          .required('Company name is required')
          .matches(/\S+/, 'Company name cannot be blank'),
        jobTitle: Yup.string()
          .required('Job title is required')
          .matches(/\S+/, 'Job title cannot be blank'),
        yearOfExperiance: Yup.string()
          .required('Year of experience is required')
          .matches(/\S+/, 'Year of experience cannot be blank')
          .matches(/^[0-9]+$/, 'Please enter a valid experience.'),
        city: Yup.string()
          .required('City is required')
          .matches(/\S+/, 'City cannot be blank'),
        joinningDate: Yup.date()
          .required('Joining date is required')
          .nullable()
          .max(new Date(), 'Joining date cannot be in the future'),
        tillDate: Yup.date()
          .required('Till date is required')
          .nullable()
          .min(Yup.ref("joinningDate"), "Till date cannot be before Joining Date")
          .max(new Date(), 'Till date cannot be in the future.'),
        experienceLetter: Yup.mixed()
          .test("fileSize", "The experience letter size exceeds the limit of 2MB. Please upload a smaller file.", (value) => {
            return !value || (value && value.size <= MAX_FILE_SIZE);
          })
          .test("fileType", "Only .jpg, .jpeg, .png, and .pdf files are allowed.", (value) => {
            return (
              !value ||
              (value && ["image/jpeg", "image/png", "application/pdf"].includes(value.type))
            );
          }),
        relievingLetter: Yup.mixed()
          .test("fileSize", "The relieving letter size exceeds the limit of 2MB. Please upload a smaller file.", (value) => {
            return !value || (value && value.size <= MAX_FILE_SIZE);
          })
          .test("fileType", "Only .jpg, .jpeg, .png, and .pdf files are allowed.", (value) => {
            return (
              !value ||
              (value && ["image/jpeg", "image/png", "application/pdf"].includes(value.type))
            );
          }),
        salarySlip: Yup.mixed()
          .test("fileSize", "The salary slips size exceeds the limit of 2MB. Please upload a smaller file.", (value) => {
            return !value || (value && value.size <= MAX_FILE_SIZE);
          })
          .test("fileType", "Only .jpg, .jpeg, .png, and .pdf files are allowed.", (value) => {
            return (
              !value ||
              (value && ["image/jpeg", "image/png", "application/pdf"].includes(value.type))
            );
          }),
      })
    ),
  }),
  skillSet: Yup.object({
    skills: Yup.array()
      .min(3, 'At least 3 skills are required.')
      .required(),
    tools: Yup.array()
      .min(1, 'At least 1 tool is required.')
      .required(),
  }),
});
export default professionalDetailsValidationSchema;
