import React from 'react';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { isEmpty } from 'lodash';
import TagIcon from '@mui/icons-material/Tag';
import { ColSpan, Grid } from '../../../../style/utils/Grid.styled';
import { ViewChip } from '../controls/ViewChip';
import { ViewDividerWithTitle } from '../controls/ViewDividerWithTitle';
import { ViewField } from '../controls/ViewField';
import {
  ViewDetailsContentItems,
  ViewDetailsItemTwo,
  ViewDetailsItemWithCard,
  ViewDetailsItemWithChips,
  ViewDetailsTitleWithIcon,
  ViewDetailsWrap,
} from '../../../../style/components/ApplicationView.styled';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


export const ProfessionalDetailsView = ({ details }) => {
  const {
    skillSet,
    personalDetails,
    experianceDetails,
    portfolioDetails,
  } = details;
  console.log('🚀 ~ ProfessionalDetailsView ~ experianceDetails:', experianceDetails);

  return (
    <ViewDetailsWrap>
      {/* 1)  skill sets */}
      {!isEmpty(skillSet) ? (
        <ViewDetailsItemWithChips>
          <ViewChip name={'Skills'} value={skillSet.skills} />
          <ViewChip name={'Tools'} value={skillSet.tools} />
        </ViewDetailsItemWithChips>
      ) : null}

      {/* 2) experiance details */}
      {!isEmpty(experianceDetails) &&
        !isEmpty(experianceDetails[0].companyName) ? (
        <ViewDetailsContentItems>
          <ViewDividerWithTitle title={'Experiance Details'} />
          {/* <ViewField
            name={'Experiance Status'}
            value={personalDetails.experianceStatus}
          /> */}
          {experianceDetails.map((exp, index) => {
            return (
              <React.Fragment key={index}>
                <ViewDetailsTitleWithIcon>
                  <TagIcon color="primary" />
                  <Typography variant="h6">{`Experiance ${index + 1}`}</Typography>
                </ViewDetailsTitleWithIcon>
                <ViewDetailsItemTwo>
                  <ViewField
                    name={'Company name'}
                    value={experianceDetails[index].companyName}
                  />
                  <ViewField
                    name={'Job title'}
                    value={experianceDetails[index].jobTitle}
                  />
                  <ViewField
                    name={'City'}
                    value={experianceDetails[index].city}
                  />
                  <ViewField
                    name={'Year of Experiance'}
                    value={experianceDetails[index].yearOfExperiance}
                  />
                  <ViewField
                    name={'Start date'}
                    value={moment(experianceDetails[index].joinningDate).format(
                      'LL',
                    )}
                  />
                  <ViewField
                    name={'End Date'}
                    value={moment(experianceDetails[index].tillDate).format(
                      'LL',
                    )}
                  />
                  {/* <ViewChip
                    name={'Projects'}
                    value={experianceDetails[index].projects}
                  /> */}
                </ViewDetailsItemTwo>
                <div className="experience-document-div">
                  {(experianceDetails?.[index].experienceLetterUrl
                    || experianceDetails?.[index].relievingLetterUrl
                    || experianceDetails?.[index].salarySlipUrl) && (
                      <Typography variant="subtitle1" sx={{ letterSpacing: 1.2 }}>
                        Documents
                      </Typography>
                    )}
                  <div className="experience-document">
                    <List>
                      {experianceDetails?.[index]?.experienceLetterUrl && (
                        <Link
                          href={experianceDetails[index].experienceLetterUrl}
                          underline="none"
                          target="_blank"
                          rel="noreferrer noopener"
                          aria-label="Open Experience Letter"
                        >
                          <ListItem key={experianceDetails[index].experienceLetterUrl}>
                            <ListItemAvatar>
                              <Avatar>
                                <PictureAsPdfIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                              Experience letter
                            </ListItemText>
                          </ListItem>
                        </Link>
                      )}
                    </List>
                    <List>
                      {experianceDetails?.[index]?.relievingLetterUrl && (
                        <Link
                          href={experianceDetails[index].relievingLetterUrl}
                          underline="none"
                          target="_blank"
                          rel="noreferrer noopener"
                          aria-label="Open Experience Letter"
                        >
                          <ListItem key={experianceDetails[index].relievingLetterUrl}>
                            <ListItemAvatar>
                              <Avatar>
                                <PictureAsPdfIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                              Relieving letter
                            </ListItemText>
                          </ListItem>
                        </Link>
                      )}
                    </List>
                    <List>
                      {experianceDetails?.[index]?.salarySlipUrl && (
                        <Link
                          href={experianceDetails[index].salarySlipUrl}
                          underline="none"
                          target="_blank"
                          rel="noreferrer noopener"
                          aria-label="Open Experience Letter"
                        >
                          <ListItem key={experianceDetails[index].salarySlipUrl}>
                            <ListItemAvatar>
                              <Avatar>
                                <PictureAsPdfIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                              Salary slip
                            </ListItemText>
                          </ListItem>
                        </Link>
                      )}
                    </List>
                  </div>
                </div>
                <ViewDividerWithTitle title={index + 1} />
              </React.Fragment>
            );
          })}
        </ViewDetailsContentItems>
      ) : null}

      {/* 3) portfolio Details */}
      {!isEmpty(portfolioDetails) && !isEmpty(portfolioDetails[0].title) ? (
        <ViewDetailsContentItems>
          <ViewDividerWithTitle title={'Portfolio Details'} />
          <ViewDetailsItemWithCard>
            {portfolioDetails.map((prtFl, index) => {
              return (
                <Card variant="outlined" key={index + 1}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {portfolioDetails[index].title}
                    </Typography>
                    <Typography variant="body">
                      {portfolioDetails[index].description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      href={portfolioDetails[index].link}
                      endIcon={<OpenInNewRoundedIcon />}
                      target="_blank"
                    // variant="outlined"
                    >
                      VISIT
                    </Button>
                  </CardActions>
                </Card>
              );
            })}
          </ViewDetailsItemWithCard>
        </ViewDetailsContentItems>
      ) : null}
    </ViewDetailsWrap>
  );
};
