import { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../../context/Context';
import { FormNav } from '../../components/nav/FormNav';
import PersonalDetailsForm from './forms/PersonalDetailsForm';
import AddressDetailsForm from './forms/AddressDetailsForm';
import FormFooterButtons from '../../components/footer/FormFooterButtons';
import personalDetailsValidationSchema from './statistics/personalDetailsValidationSchema';
import showSnackbar from '../../components/message/showSnackbar';

const PersonalDetailsPage = () => {
  const navigate = useNavigate();
  const {
    personalDets,
    savePersonalDetails,
    getPersonalDetails,
    setSpineersLoader,
    loading,
    setUnSaveAppData,
    setIsCompleteAppInfoVisible,
  } = useContext(MyContext);

  useEffect(() => {
    const fetchData = async () => {
      await getPersonalDetails();
    };
    fetchData();
  }, []);
  return (
    <section>
      <FormNav />
      {loading ? (
        <>
          <Skeleton variant="rectangular" animation="wave" width="100%" height={100} />
          <Skeleton variant="rectangular" animation="wave" width="100%" height={400} />
          <Skeleton variant="rectangular" animation="wave" width="100%" height={400} />
        </>
      ) : (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={personalDets}
            validationSchema={personalDetailsValidationSchema}
            onSubmit={async (value) => {
              setSpineersLoader(true);
              const responseData = await savePersonalDetails(value);
              if (responseData.success) {
                if (responseData?.message) {
                  showSnackbar(responseData.message, { variant: 'success' });
                }
                setIsCompleteAppInfoVisible(true);
                navigate(responseData.location);
              }
              if (!responseData.success) {
                showSnackbar(responseData.message, {
                  variant: 'error',
                });
              }
              setSpineersLoader(false);
            }}
          >
            {(props) => {
              setUnSaveAppData(props.values);
              return (
                <Form className="flex">
                  <PersonalDetailsForm formController={props} />
                  <AddressDetailsForm formController={props} />
                  <FormFooterButtons />
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </section>
  );
};

export default PersonalDetailsPage;
