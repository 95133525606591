// Globale Styles

import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
body {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: ${({ theme }) => theme.fontSize.body};
    font-weight: ${({ theme }) => theme.fontWeight.w2};
    background-color: ${({ theme }) => theme.color.def};
    overflow-x: hidden;
    letter-spacing: 1.5;
}
a {
    text-decoration: none;
}
section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-block: 1rem;
}
::-webkit-scrollbar {display:none;}
.flex {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.flex-no-gap{
    display: flex;
    flex-direction: column;
}
.avatar-thumbnail-span{
    display: flex;
    align-items: center;
    gap: 0.25rem;
}
.avatar-thumbnail-image{
    border-radius: 1rem;
    border-style:dashed;
    border-width:0.10rem;
    border-color:rgb(0, 125, 184);
}
.image-thumbnail-2{
    max-width:2rem;
    aspect-ratio:1/1;
    object-fit:cover;
}
.image-aspect-ratio{
    aspect-ratio:1/1;
    object-fit: cover;
}
.center-the-snippet{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

`;
