import axios from 'axios';
import { apiErrData, apiResData } from '../func/apiResDataHandler';

const apiURL = process.env.REACT_APP_CANDIDATE_API_URL;
const api = axios.create({
  baseURL: apiURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  // timeout: 1000,
});

const loginApi = (apiArgs) => {
  const config = {
    method: 'post',
    url: '/login',
    data: apiArgs,
  };

  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const logoutApi = () => {
  const config = {
    method: 'get',
    url: '/logout',
  };
  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

const isLoginApi = async () => {
  const config = {
    method: 'get',
    url: '/is-loggedin',
  };
  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const personalDetsApi = async (apiArgs) => {
  const config = {
    method: 'post',
    url: '/personal-details',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: apiArgs,
  };
  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

const getPersonalDetsApi = async (apiArgs) => {
  const config = {
    method: 'get',
    url: '/personal-details',
    params: apiArgs,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

const professionalDetsApi = async (apiArgs) => {
  const config = {
    method: 'post',
    url: '/professional-details',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: apiArgs,
  };
  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

const getProfessionalDetsApi = async () => {
  const config = {
    method: 'get',
    url: 'professional-details',
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};
const deleteExperience = async (id) => {
  const config = {
    method: 'delete',
    url: `experiance/${id}`,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};
const deletePortFolio = async (id) => {
  const config = {
    method: 'delete',
    url: `portfolio/${id}`,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};
const deleteEducation = async (id) => {
  const config = {
    method: 'delete',
    url: `education/${id}`,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};
const deleteCourses = async (id) => {
  const config = {
    method: 'delete',
    url: `courses/${id}`,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};
const deleteEmergencyContact = async (id) => {
  const config = {
    method: 'delete',
    url: `emergencyContact/${id}`,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const otherDetsApi = async (apiArgs) => {
  const config = {
    method: 'post',
    url: '/other-details',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: apiArgs,
  };
  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

const getOtherDetsApi = async () => {
  const config = {
    method: 'get',
    url: '/other-details',
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const getAppDetsApi = async () => {
  const config = {
    method: 'get',
    url: '/application',
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const viewOnBoardAppApi = async (apiArgs) => {
  const config = {
    method: 'get',
    url: '/application/view',
    params: apiArgs,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const verifyAppKey = async (apiArgs) => {
  const config = {
    method: 'get',
    url: '/application/verify-application-key',
    params: apiArgs,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

const appStatuUpdateApi = (apiArgs) => {
  const config = {
    method: 'patch',
    url: '/application/status',
    data: apiArgs,
  };
  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

const getAppLogApi = () => {
  const config = {
    method: 'get',
    url: '/application/log',
  };
  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const adminCandidateViewApi = async (apiArgs) => {
  const config = {
    method: 'get',
    url: '/application/admin-view',
    params: apiArgs,
  };
  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const chnagePasswordRequestApi = async (apiArgs) => {
  const config = {
    method: 'post',
    url: '/change-password',
    data: apiArgs,
  };
  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const addNewPassword = async (apiArgs) => {
  const config = {
    method: 'post',
    url: '/change-password/add-new-password',
    data: apiArgs,
  };
  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const verifyPasswordLink = async (apiArgs) => {
  const config = {
    method: 'get',
    url: '/change-password/verify-link',
    params: apiArgs,
  };
  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

/**
 * No use of this functions
 * - verifyAppKey,
 */

const fetchStreamApi = async () => await api.get('/stream');

const fetchDegreesApi = async () => await api.get('/degree');

const addDegreeApi = async (data) => await api.post('/degree', data);

const addStreamApi = async (data) => await api.post('/stream', data);

export {
  loginApi,
  logoutApi,
  isLoginApi,
  personalDetsApi,
  getPersonalDetsApi,
  professionalDetsApi,
  getProfessionalDetsApi,
  otherDetsApi,
  getOtherDetsApi,
  getAppDetsApi,
  viewOnBoardAppApi,
  verifyAppKey,
  appStatuUpdateApi,
  getAppLogApi,
  adminCandidateViewApi,
  chnagePasswordRequestApi,
  addNewPassword,
  verifyPasswordLink,
  deleteExperience,
  deletePortFolio,
  deleteEducation,
  deleteCourses,
  deleteEmergencyContact,
  fetchStreamApi,
  fetchDegreesApi,
  addDegreeApi,
  addStreamApi,
};
