const EducationDetailsInit = {
  stream: '',
  degree: '',
  institute: '',
  boardOrUniversity: '',
  gpaOrPercentage: '',
  passingYear: null,
};
const CoursesDetailsInit = {
  courseName: '',
  institute: '',
  completionDate: null,
};
const BankDetailsInit = {
  bankName: '',
  branch: '',
  accountHolder: '',
  accountNo: '',
  ifscNo: '',
  document: null,
  attachment: null,
};
const EmergencyContactInit = {
  fullname: '',
  contactNo: '',
  relation: '',
  city: '',
  note: '',
};
const OtherPersonalDetailsInit = {
  maritalStatus: '',
  partnerName: '',
  marriageAnniversary: null,
  languages: [],
  hobbies: [],
  bloodType: '',
};

export {
  EducationDetailsInit,
  CoursesDetailsInit,
  BankDetailsInit,
  EmergencyContactInit,
  OtherPersonalDetailsInit,
};
