import { FormHelperText, Typography } from '@mui/material';
import { ErrorMessage, FieldArray } from 'formik';
import { FormWrap } from '../../../../style/components/form/StyledForm.styled';
import {
  FormGroup,
  Label,
  Required,
} from '../../../../style/elements/form/Form.styled';
import { ErrorFiled } from '../../../../style/elements/form/FormError.styled';
import { Grid } from '../../../../style/utils/Grid.styled';
import { FormTag } from '../../../components/form/formControl/FormTag';

const SkillDetailsForm = ({ formController }) => {
  const { values, touched, errors } = formController;
  return (
    <FormWrap>
      <Typography variant="h6">Skill Sets</Typography>
      <Grid gap={'1rem'}>
        <FormGroup>
          <Label label={'Primary Skills'} required={true} icon={<Required />} />
          <FieldArray name="skillSet.skills">
            {(props) => {
              return (
                <FormTag
                  label={'Skills'}
                  taggArr={values.skillSet.skills}
                  push={props.push}
                  remove={props.remove}
                  dir={'30% auto'}
                  wd={'220px'}
                />
              );
            }}
          </FieldArray>
          <div style={{ minHeight: '22px', marginTop: '-2px', marginBottom: '-6px' }}>
            <ErrorMessage
              name="skillSet.skills"
              component={ErrorFiled}
              wd={'253px'}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Label label={'Tools'} required={true} icon={<Required />} />
          <FieldArray name="skillSet.tools">
            {(props) => {
              return (
                <FormTag
                  label={'Tools'}
                  taggArr={values.skillSet.tools}
                  push={props.push}
                  remove={props.remove}
                  dir={'30% auto'}
                  wd={'220px'}
                />
              );
            }}
          </FieldArray>
          <div style={{ minHeight: '22px', marginTop: '-2px', marginBottom: '-6px' }}>
            <ErrorMessage
              name="skillSet.tools"
              component={ErrorFiled}
              wd={'272px'}
            />
          </div>
        </FormGroup>
      </Grid>
    </FormWrap>
  );
};

export default SkillDetailsForm;
