import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Skeleton,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MyContext } from '../../context/Context';
import PersonalDetailsView from './accordion_details/PersonalDetailsView';
import { ProfessionalDetailsView } from './accordion_details/ProfessionalDetailsView';
import { OtherDetailsView } from './accordion_details/OtherDetailsView';

const ExaminingApplication = () => {
  const {
    examiningCandidateOnBoardApp,
    personalDets,
    professionalDets,
    otherDets,
  } = useContext(MyContext);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [params] = useSearchParams();
  const getAppKey = params.get('applicationKey');

  const accordionDetailsSection = [
    {
      id: 'personal-details',
      defExpanded: true,
      title: 'Personal Details',
      component: <PersonalDetailsView details={personalDets} />,
    },
    {
      id: 'professional-details',
      defExpanded: true,
      title: 'Professional Details',
      component: <ProfessionalDetailsView details={professionalDets} />,
    },
    {
      id: 'other-details',
      defExpanded: true,
      title: 'Other Details',
      component: <OtherDetailsView details={otherDets} />,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        await examiningCandidateOnBoardApp({ applicationKey: getAppKey });
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div>
        {[...Array(5)].map((_, index) => (
          <div key={index}>
            <Skeleton variant="text" width={210} height={40} />
            <Skeleton variant="rectangular" width="100%" height={118} />
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <section>
      {accordionDetailsSection.map(({ id, defExpanded, title, component }) => (
        <Accordion defaultExpanded={defExpanded} key={id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${id}-content`}
            id={id}
          >
            <Typography>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>{component}</AccordionDetails>
        </Accordion>
      ))}
    </section>
  );
};

export default ExaminingApplication;
