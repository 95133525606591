import { useContext, useState } from 'react';
import { FieldArray } from 'formik';
import {
  CircularProgress,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { has, isEmpty } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { ColSpan, Grid } from '../../../../style/utils/Grid.styled';
import { EmergencyContactInit } from '../statistics/otherDetailsInitialValues';
import { RelationOptions } from '../statistics/otherDetailsSelectOptions';
import { FormContentInputDiv, FormWrap, FromContentTwo } from '../../../../style/components/form/StyledForm.styled';
import ErrorMessage from '../../../components/message/ErrorMessage';
import CustomTextField from '../../../components/form/formControl/TextField';
import showSnackbar from '../../../components/message/showSnackbar';
import { deleteEmergencyContact } from '../../../../api/apis';
import { MyContext } from '../../../context/Context';
const EmergencyContactDetailsForm = () => {
  // State to control the visibility of the delete confirmation dialog
  const [ContactDiaOpen, setContactDiaOpen] = useState(false);

  const [delContactId, setDelContactId] = useState(null);
  const [loading, setLoading] = useState(false);

  // Extracting the getProfessionalDetails function from the context
  const { getCandidateOtherDetails } = useContext(MyContext);

  // Function to handle the initiation of the delete process
  const handleDelContact = (id) => {
    // Set the ID of the experience to be deleted
    setDelContactId(id);
    // Open the delete confirmation dialog
    setContactDiaOpen(true);
  };

  // Function to handle the confirmation of the delete action
  const handleDelContactConfirm = async () => {
    setLoading(true);
    // Call the API to delete
    const apiRes = await deleteEmergencyContact(delContactId);
    // Destructure the API response
    const { success, message } = apiRes;

    if (success) {
      // If the deletion was successful
      // Show a success message
      showSnackbar(message, { variant: 'success' });
      // Close the delete confirmation dialog
      setContactDiaOpen(false);
      // Refresh the professional details
      getCandidateOtherDetails();
    } else {
      // If the deletion failed
      // Show an error message
      showSnackbar(message, { variant: 'error' });
    }
    setLoading(false);
  };

  // Function to handle the cancellation of the delete action
  const handleDelContactCancel = () => {
    // Close the delete confirmation dialog without deleting
    setContactDiaOpen(false);
  };
  return (
    <FormWrap>
      <Typography variant="h6">Emergency Contact</Typography>
      <FieldArray name="emergencyContactDetails">
        {(props) => {
          const { remove, push, form } = props;
          const {
            values,
            handleChange,
            errors,
            touched,
          } = form;
          const { emergencyContactDetails } = values;
          return (
            <Grid gap={'1rem'}>
              {emergencyContactDetails !== undefined &&
                emergencyContactDetails !== null &&
                emergencyContactDetails.length > 0
                ? emergencyContactDetails.map((item, index) => {
                  return (
                    <Card variant="outlined" key={index}>
                      <CardActions
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography gutterBottom>Details</Typography>
                        {emergencyContactDetails.length !== 1 && (
                          <IconButton
                            // onClick={() => remove(index)}
                            onClick={() => {
                              const delId = emergencyContactDetails[index]?.id;
                              if (delId) {
                                handleDelContact(delId);
                              } else {
                                // handleDelContact(delId);
                                remove(index);
                              }
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </CardActions>
                      <CardContent>
                        <FromContentTwo>
                          <CustomTextField
                            label="Fullname"
                            name={`emergencyContactDetails.${index}.fullname`}
                            value={
                              values.emergencyContactDetails[index].fullname
                            }
                            onChange={handleChange}
                            errors={
                              has(errors, `emergencyContactDetails.${index}.fullname`) &&
                                touched.emergencyContactDetails?.[index]?.fullname ?
                                errors.emergencyContactDetails[index].fullname : ''
                            }
                            touched={touched.emergencyContactDetails?.[index]?.fullname}
                            variant="outlined"
                          />
                          <CustomTextField
                            label="Contact No."
                            name={`emergencyContactDetails.${index}.contactNo`}
                            // type="number"
                            value={
                              values.emergencyContactDetails[index].contactNo
                            }
                            onChange={handleChange}
                            errors={
                              has(errors, `emergencyContactDetails.${index}.contactNo`) &&
                                touched.emergencyContactDetails?.[index]?.contactNo ?
                                errors.emergencyContactDetails[index].contactNo : ''
                            }
                            touched={touched.emergencyContactDetails?.[index]?.contactNo}
                            variant="outlined"
                          />
                          <CustomTextField
                            label="City"
                            name={`emergencyContactDetails.${index}.city`}
                            value={values.emergencyContactDetails[index].city}
                            onChange={handleChange}
                            // errors={
                            //   has(errors, `emergencyContactDetails.${index}.city`) &&
                            //     touched.emergencyContactDetails?.[index]?.city ?
                            //     errors.emergencyContactDetails[index].city : ''
                            // }
                            errors={
                              touched.emergencyContactDetails?.[index]?.city
                              && errors.emergencyContactDetails?.[index]?.city
                            }
                            touched={touched.emergencyContactDetails?.[index]?.city}
                            variant="outlined"
                          />
                          <FormControl>
                            <InputLabel
                              id="emergencyContactRelation"
                              error={
                                touched.emergencyContactDetails?.[index]?.relation
                                && Boolean(errors.emergencyContactDetails?.[index]?.relation)
                              }
                            >
                              Relation
                            </InputLabel>
                            <FormContentInputDiv>
                              <Select
                                labelId="emergencyContactRelation"
                                label="Relation"
                                name={`emergencyContactDetails.${index}.relation`}
                                value={
                                  values.emergencyContactDetails[index].relation
                                }
                                onChange={handleChange}
                                // error={
                                //   !isEmpty(touched) && !isEmpty(errors) &&
                                //   has(errors, 'emergencyContactDetails') &&
                                //   !isEmpty(touched.emergencyContactDetails[index]) &&
                                //   touched.emergencyContactDetails[index].relation &&
                                //   !isEmpty(errors.emergencyContactDetails[index]) &&
                                //   Boolean(errors.emergencyContactDetails[index].relation)
                                // }
                                error={
                                  touched.emergencyContactDetails?.[index]?.relation
                                  && Boolean(errors.emergencyContactDetails?.[index]?.relation)
                                }
                              >
                                {RelationOptions.map((relation, i) => {
                                  const { value, label } = relation;
                                  return (
                                    <MenuItem key={i} value={value}>
                                      {label}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              <ErrorMessage
                                message={
                                  touched.emergencyContactDetails?.[index]?.relation
                                  && errors.emergencyContactDetails?.[index]?.relation
                                }
                              />
                            </FormContentInputDiv>
                          </FormControl>
                          <ColSpan colSpan={'1 / span 2'}>
                            <TextField
                              fullWidth
                              multiline
                              rows={4}
                              label="Note"
                              name={`emergencyContactDetails.${index}.note`}
                              value={
                                values.emergencyContactDetails[index].note
                              }
                              onChange={handleChange}
                              error={
                                touched.emergencyContactDetails?.[index]?.note
                                && errors.emergencyContactDetails?.[index]?.note
                              }
                            />
                          </ColSpan>
                          <ErrorMessage
                            message={
                              touched.emergencyContactDetails?.[index]?.note
                              && errors.emergencyContactDetails?.[index]?.note
                            }
                          />
                        </FromContentTwo>
                      </CardContent>
                    </Card>
                  );
                })
                : null}
              <Grid jc={'center'}>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  sx={{
                    borderRadius: '50%',
                    aspectRatio: '1/1',
                    minWidth: '0.2rem',
                    padding: '0.5rem',
                  }}
                >
                  <AddIcon onClick={() => push(EmergencyContactInit)} />
                </Button>
              </Grid>
            </Grid>
          );
        }}
      </FieldArray>
      <Dialog open={ContactDiaOpen} onClose={handleDelContactCancel}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this item?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDelContactCancel}
            color="primary"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelContactConfirm}
            color="primary"
            autoFocus
            disabled={loading}
            startIcon={loading ? <CircularProgress size={10} color="inherit" /> : null}
          >
            {loading ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </FormWrap>
  );
};

export default EmergencyContactDetailsForm;
