import * as Yup from 'yup';
import 'yup-phone';

const personalDetailsValidationSchema = Yup.object({
  firstname: Yup.string()
    .trim('First name cannot contain only spaces.')
    .min(1, 'First name cannot be blank.')
    .required('Please enter your first name.'),

  lastname: Yup.string()
    .trim('Last name cannot contain only spaces.')
    .min(1, 'Last name cannot be blank.')
    .required('Please enter your last name.'),

  email: Yup.string()
    .email('Please enter a valid email address.')
    .required('Email is required.'),

  contactNo: Yup.string()
    .trim('Contact number cannot contain only spaces.')
    .matches(/^[0-9]+$/, 'Contact number must contain only digits.')
    .length(10, 'Contact number must be exactly 10 digits long.')
    .required('Contact number is required.'),

  birthDate: Yup.date()
    .nullable()
    .required('Please enter your date of birth.')
    .max(new Date(), 'Date of birth cannot be in the future.'),

  age: Yup.string()
    .trim('Age cannot contain only spaces.')
    .matches(/^[0-9]+$/, 'Please enter a valid age.')
    .min(1, 'Age cannot be blank.')
    .required('Please enter your age.'),

  gender: Yup.string()
    .trim('Gender cannot contain only spaces.')
    .min(1, 'Gender cannot be blank.')
    .required('Please select your gender.'),

  nationality: Yup.string()
    .trim('Nationality cannot contain only spaces.')
    .min(1, 'Nationality cannot be blank.')
    .required('Please enter your nationality.'),

  permanentStreet: Yup.string()
    .trim('Street address cannot contain only spaces.')
    .min(1, 'Street address cannot be blank.')
    .required('Please enter your street address.'),

  permanentCity: Yup.string()
    .trim('City cannot contain only spaces.')
    .min(1, 'City cannot be blank.')
    .required('Please enter your city.'),

  permanentPincode: Yup.string()
    .trim('Pincode cannot contain only spaces.')
    .matches(/^[0-9]+$/, 'Pleease enter a valid pincode.')
    .min(1, 'Pincode cannot be blank.')
    .required('Please enter your pincode.'),

  permanentState: Yup.string()
    .trim('State cannot contain only spaces.')
    .min(1, 'State cannot be blank.')
    .required('Please enter your state.'),

  permanentCountry: Yup.string()
    .trim('Country cannot contain only spaces.')
    .min(1, 'Country cannot be blank.')
    .required('Please enter your country.'),
  avatarAttachment: Yup.mixed()
    .test({
      message: 'Only PNG, JPG, or JPEG files are allowed.',
      test: (fileHolder) => {
        if (!fileHolder) {
          return true;
        }
        if (fileHolder) {
          return !(fileHolder && !fileHolder.name.match(/\.(jpg|jpeg|png)$/));
        }
      },
    })
    .test({
      message: 'Image size should not exceed 2MB.',
      test: (fileHolder) => {
        if (!fileHolder) return true;
        return fileHolder.size / (1024 * 1024) <= 2;
      },
    }),
});

export default personalDetailsValidationSchema;
